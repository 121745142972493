<template>
  <div>
    <v-app-bar app class="elevation-1" clipped-left color="nav" dense>
      <v-app-bar-nav-icon
          v-if="$store.state.axios"
          @click="$emit('update:showDrawer', !showDrawer)"
      />
      <div class="d-flex align-center headline font-weight-bold navContent--text">
        <v-icon color="navContent">mdi-ice-cream</v-icon>
        Vanilla
      </div>
      <v-spacer/>
      <div v-if="isLoggedIn">
        <app-bar-search-control/>
      </div>
      <div v-if="isLoggedIn">
        <app-bar-notification-center/>
      </div>
      <div v-if="isLoggedIn && $$user.isEmployee">
        <v-btn color="navContent" icon @click="showMessagingDialog = true">
          <v-icon>mdi-message-text</v-icon>
        </v-btn>
      </div>
      <div v-if="isLoggedIn">
        <v-badge :value="openStacks.length" dot color="error" top right :offset-y="20" :offset-x="12">
          <v-btn color="navContent" icon @click="showStackDialog = true">
            <v-icon>mdi-tray-full</v-icon>
          </v-btn>
        </v-badge>
      </div>
      <div v-if="isLoggedIn && canUseGmapsDistanceMatrix">
        <v-btn color="navContent" icon @click="showGmapsSearchDialog = true;">
          <v-icon>mdi-map-search</v-icon>
        </v-btn>
      </div>
      <div v-if="isLoggedIn" class="ml-2">
        <app-bar-profile-menu v-if="$store.state.axios" @show:createAvataaarDialog="showCreateAvataaarDialog = true"/>
      </div>
      <!-- DIALOGS -->
      <create-avataaar-dialog v-model="showCreateAvataaarDialog" type="employee"/>
      <gmaps-search-dialog v-if="$store.state.axios && canUseGmapsDistanceMatrix" v-model="showGmapsSearchDialog"/>
      <stack-dialog :active-stacks="openStacks" v-if="$store.state.axios" v-model="showStackDialog"/>
      <messaging-dialog v-if="$store.state.axios && $$user.isEmployee" v-model="showMessagingDialog"/>

    </v-app-bar>
    <!-- NOTIFICATIONS -->
    <div v-if="isLoggedIn" class="notification-area">
      <div class="notification-background"/>
      <app-notifications/>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {ConfigHandlerAPI} from '@/classes/api/config-handler.api.class';
import {Config} from '@/interfaces/config.interface';
import StackDialog from '@/components/stack/StackDialog.vue';
import AppBarProfileMenu from '@/components/app/appBar/appBarProfileMenu.vue';
import AppBarSearchControl from '@/components/app/appBar/appBarSearchControl.vue';
import GmapsSearchDialog from '@/components/app/gmapsSearchDialog.vue';
import {CustomAccessKeys} from '@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys';
import CreateAvataaarDialog from '@/components/common/profile/createAvataaarDialog.vue';
import AppNotifications from '@/components/app/appBar/notifications/appNotifications/appNotifications.vue';
import AppBarNotificationCenter
  from '@/components/app/appBar/notifications/notificationCenter/appBarNotificationCenter.vue';
import MessagingDialog from '@/components/app/messagingDialog.vue';
import {StackManagerApi} from "@/classes/api/stack-manager.api.class";
import {isObjectId, retrieveObjectId} from "@/helpers/isObjectId.helper";
import mongoose from "mongoose";
import {StackItemStates} from "@/enums/StackItemStates.enum";
import {Stack} from "@/interfaces/stack.interface";

export default Vue.extend({
  components: {
    MessagingDialog,
    AppBarNotificationCenter,
    AppNotifications,
    CreateAvataaarDialog, AppBarSearchControl, AppBarProfileMenu, StackDialog, GmapsSearchDialog,
  },
  props: {
    showDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showStackDialog: false,
    showCreateAvataaarDialog: false,
    showMessagingDialog: false,
    showSearchInput: false,
    showUserMenu: true,
    showGmapsSearchDialog: false,
    loading: false,
    customerConfig: null as null | Config,
    openStacks: [] as Stack[],
  }),
  computed: {
    isLoggedIn(): boolean {
      return !!this.$store.state.axios;
    },
    canUseGmapsDistanceMatrix(): boolean {
      return !!this.$store.state.crudAccessManager.getCustom(CustomAccessKeys.USE_GMAPS_DISTANCE_MATRIX);
    },
  },
  methods: {
    async getConfig() {
      try {
        this.loading = true;
        this.customerConfig = await ConfigHandlerAPI.getConfig();
      } finally {
        this.loading = false;
      }
    },
    async getStacks() {
      this.openStacks = [];
      const path = this.$route.path.split('/').filter((el) => el !== '');
      if (path.length >= 2) {
        const entity = path[0];
        const id = path[1];
        if (!['parents', 'students', 'employees', 'institutions'].includes(entity) || !isObjectId(id)) {
          return;
        }
        const stackResp = await StackManagerApi.find({
          filter: {
            isClosed: false,
            meta: [id as mongoose.Types.ObjectId],
          },
        });
        if (stackResp.stacks.length > 0) {
          const openStacks = [];
          for (const stack of stackResp.stacks) {
            const found = stack.items.filter((el) => {
              const metaId = retrieveObjectId(el.meta);
              return metaId.toString() === id.toString() && ![StackItemStates.DONE, StackItemStates.MUTED].includes(el.state);
            });
            if (found.length > 0) {
              openStacks.push(stack);
            }
          }
          this.openStacks = openStacks;
        }

      }
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.getStacks();
      },
    },
    '$store.state.user': {
      deep: true,
      handler() {
        this.showUserMenu = false;
        setTimeout(() => {
          this.showUserMenu = true;
        }, 500);
      },
    },
  },
  beforeDestroy() {
    this.openStacks = [];
  },
});
</script>

<style scoped>
.notification-area {
  z-index: 4;
  position: fixed;
  right: 0;
  top: 40px;
  max-height: calc(100vh - 40px);
}

.notification-area > .notification-background {
  position: absolute;
  top: 5px;
  right: 0;
  left: 30px;
  bottom: 10px;
  filter: blur(20px);
}

@media (prefers-color-scheme: light) {
  .notification-area > .notification-background {
    background-color: rgba(230, 230, 255, 0.50);
  }
}

@media (prefers-color-scheme: dark) {
  .notification-area > .notification-background {
    background-color: rgba(20, 20, 40, 0.5);
  }
}

</style>

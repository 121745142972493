<template>
  <v-menu v-model="showMenu" :close-on-content-click="false" nudge-bottom="5" offset-y transition="slide-y-transition">
    <template v-slot:activator="{on}">
      <v-btn color="navContent" icon v-on="on">
        <v-badge :value="unreadCount >= 1" color="red" dot offset-x="8" overlap>
          <v-slide-x-transition>
            <v-icon>mdi-bell-outline</v-icon>
            {{ unreadCount }}
          </v-slide-x-transition>
        </v-badge>
      </v-btn>
    </template>
    <div>
      <v-row>
        <v-col>
          <v-tabs v-model="tab" color="info" grow>
            <v-tab href="#open">Offen <span v-if="openNotifications.length > 0">({{ openNotifications.length }})</span>
            </v-tab>
            <v-tab href="#dismissed">Papierkorb <span
                v-if="dismissedNotifications.length > 0">({{ dismissedNotifications.length }})</span></v-tab>
          </v-tabs>
        </v-col>
        <v-col class="pt-4 ml-n4" style="max-width: 64px">
          <v-hover v-slot:default="{hover}">
            <div>
              <v-tooltip v-if="muted" left transition="slide-x-reverse-transition">
                <template v-slot:activator="{on}">
                  <v-btn icon @click="toggleMute" v-on="on">
                    <v-fab-transition hide-on-leave group origin="center">
                      <v-icon key="static" v-if="hover">mdi-volume-high</v-icon>
                      <v-icon key="active" v-else>mdi-volume-mute</v-icon>
                    </v-fab-transition>
                  </v-btn>
                </template>
                <span>Systemsounds aktivieren</span>
              </v-tooltip>
              <v-tooltip v-else left transition="slide-x-reverse-transition">
                <template v-slot:activator="{on}">
                  <v-btn icon @click="toggleMute" v-on="on">
                    <v-fab-transition hide-on-leave group origin="center">
                      <v-icon key="static" v-if="hover">mdi-volume-mute</v-icon>
                      <v-icon key="active" v-else>mdi-volume-high</v-icon>
                    </v-fab-transition>
                  </v-btn>
                </template>
                <span>Systemsounds deaktivieren</span>
              </v-tooltip>
            </div>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <v-skeleton-loader v-if="loading" style="width: 520px" type="list-item-avatar-two-line@3"/>
    <v-tabs-items v-else v-model="tab" style="width: 520px">
      <v-tab-item id="open">
        <div class="text-right">
          <v-slide-y-transition class="text-right">
            <v-btn v-if="openNotifications.length > 1" small text @click="dismissAll">
              <v-icon small>mdi-delete-empty</v-icon>
              <span>Alle löschen</span>
            </v-btn>
          </v-slide-y-transition>
        </div>
        <v-list style="max-height: 400px; overflow-y: scroll">
          <v-slide-x-reverse-transition hide-on-leave>
            <div v-if="openNotifications.length > 0">
              <v-slide-x-reverse-transition group>
                <template v-for="notification in openNotifications">
                  <v-lazy :key="notification._id?.toString()" min-height="80" transition="slide-x-transition">
                    <component :is="getComponent(notification)"
                               :component="getComponent(notification)"
                               :notification="notification" @dismiss="dismiss" @revoke="revoke"/>
                  </v-lazy>

                  <!--        <span :key="`dbg-${notification._id}`">-->
                  <!--          {{notification.deliveredAt}}-->
                  <!--        </span>-->
                </template>
              </v-slide-x-reverse-transition>
            </div>
            <v-list-item v-else>
              <v-list-item-icon>
                <v-icon>mdi-island</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Keine offenen Benachrichtigungen
                </v-list-item-title>
                <v-list-item-subtitle>
                  Hier werden deine Benachrichtigungen angezeigt.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-slide-x-reverse-transition>
        </v-list>
      </v-tab-item>
      <v-tab-item id="dismissed">
        <v-alert class="mb-0" dense tile type="warning">
          Endgültige Löschung nach 30 Tagen
        </v-alert>
        <v-list class="py-0" style="max-height: 400px; overflow-y: scroll">
          <v-slide-x-transition hide-on-leave>
            <div v-if="dismissedNotifications.length > 0">
              <v-slide-x-transition group>
                <template v-for="notification in dismissedNotifications">
                  <v-lazy  :key="notification._id?.toString()" min-height="80" transition="slide-x-transition">
                    <component :is="getComponent(notification)"
                               :component="getComponent(notification)"
                               :notification="notification" @dismiss="dismiss" @revoke="revoke"/>
                  </v-lazy>
                </template>
              </v-slide-x-transition>
            </div>
            <v-list-item v-else disabled>
              <v-list-item-icon>
                <v-icon>mdi-delete-empty</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Keine gelöschten Benachrichtigungen
                </v-list-item-title>
                <v-list-item-subtitle>
                  Hier werden gelöschte Benachrichtigungen angezeigt.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-slide-x-transition>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotifierApi} from '@/classes/api/notifier.api';
import moment from 'moment';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotificationTypes} from '@/enums/notifier/NotificationTypes';
import MentionNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/mentionNotificationItem.vue';
import {NotificationBus} from '@/busses/NotificationBus';
import mongoose from 'mongoose';
import OverdueToDoNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/overdueToDoNotificationItem.vue';
import ToDoAssignedNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/toDoAssignedNotificationItem.vue';
import ToDoCompletedNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/toDoCompletedNotificationItem.vue';
import {NotifierEvents} from '@/enums/notifier/NotifierEvents';
import CallNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/callNotificationItem.vue';
import SupervisedEntityDeactivatedNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/supervisedEntityDeactivatedNotificationItem.vue';
import EntityAssignedToSupervisorNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/entityAssignedToSupervisorNotificationItem.vue';
import WhatsAppMessageTemplateStatusUpdateNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/whatsAppMessageTemplateStatusUpdateNotificationItem.vue';
import {SoundManager} from '@/classes/clientOnly/SoundManager.class';
import CourseCancellationFailedNotificationItem
  from '@/components/app/appBar/notifications/notificationCenter/components/courseCancellationFailedNotificationItem.vue';

moment.locale('de');

export default Vue.extend({
  data: () => ({
    showMenu: false,
    limit: 10,
    loading: false,
    notifications: [] as NotificationLike[],
    hasMore: true,
    tab: 'open' as 'open' | 'dismissed',
    unreadCount: 0,
    muted: false,
  }),
  computed: {
    moment: () => moment,
    openNotifications(): NotificationLike[] {
      return this.notifications.filter((el) => !el.dismissedAt);
    },
    dismissedNotifications(): NotificationLike[] {
      return this.notifications.filter((el) => !!el.dismissedAt);
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      try {
        this.unreadCount = await NotifierApi.getUnreadCount();
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Benachrichtigungen', 'error', e);
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      this.limit += 10;
      this.loadNotifications();
    },
    toggleMute() {
      if (this.muted) {
        SoundManager.unmute();
      } else {
        SoundManager.mute();
      }
      this.muted = SoundManager.isMuted;
    },
    async loadNotifications() {
      this.loading = true;
      try {
        const resp = await NotifierApi.getOwnNotifications({
          limit: this.limit,
          sortBy: 'createdAt',
          sortDesc: true,
          markDelivered: true,
          skipPagination: true,
        });
        this.notifications = resp.notifications.map((el) => ({...el, showInApp: false}));
        this.hasMore = resp.maxPage > 1;
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Benachrichtigungen', 'error', e);
      } finally {
        this.loading = false;
      }
    },
    async dismissAll() {
      try {
        await NotifierApi.dismiss({
          notificationIds: this.openNotifications.map((el) => el._id),
        });
        this.openNotifications.forEach((el) => {
          this.$set(el, 'dismissedAt', new Date());
          if (!el.readAt) {
            this.bulkRead([el]);
          }
        });
      } catch (e) {
        this.$$showSnackbar('Fehler beim Löschen der Benachrichtigungen', 'error', e);
      }
    },
    async dismiss(id: mongoose.Types.ObjectId) {
      try {
        await NotifierApi.dismiss({
          notificationIds: [id],
        });
        const index = this.notifications.findIndex((el) => el._id === id);
        if (index >= 0) {
          this.$set(this.notifications[index], 'dismissedAt', new Date());
          if (!this.notifications[index].readAt) {
            await this.bulkRead([this.notifications[index]]);
          }
        }
      } catch (e) {
        this.$$showSnackbar('Fehler beim Löschen der Benachrichtigung', 'error', e);
      }
    },
    async revoke(id: mongoose.Types.ObjectId) {
      try {
        await NotifierApi.revokeDismission(id);
        const index = this.notifications.findIndex((el) => el._id === id);
        if (index >= 0) {
          this.$delete(this.notifications[index], 'dismissedAt');
          if (!this.notifications[index].readAt) {
            await this.bulkRead([this.notifications[index]]);
          }
        }
      } catch (e) {
        this.$$showSnackbar('Fehler beim Wiederherstellen der Benachrichtigung', 'error', e);
      }
    },
    getComponent(notification: NotificationLike) {
      switch (notification.type) {
        case NotificationTypes.MENTION:
          return MentionNotificationItem;
        case NotificationTypes.OVERDUE_TO_DO:
          return OverdueToDoNotificationItem;
        case NotificationTypes.TO_DO_ASSIGNED:
          return ToDoAssignedNotificationItem;
        case NotificationTypes.TO_DO_COMPLETED:
          return ToDoCompletedNotificationItem;
        case NotificationTypes.CALL:
          return CallNotificationItem;
        case NotificationTypes.SUPERVISED_ENTITY_DEACTIVATED:
          return SupervisedEntityDeactivatedNotificationItem;
        case NotificationTypes.ENTITY_ASSIGNED_TO_SUPERVISOR:
          return EntityAssignedToSupervisorNotificationItem;
        case NotificationTypes.WHATSAPP_MESSAGE_TEMPLATE_STATUS_UPDATE:
          return WhatsAppMessageTemplateStatusUpdateNotificationItem;
        case NotificationTypes.COURSE_CANCELLATION_FAILED:
          return CourseCancellationFailedNotificationItem;
      }
    },
    async bulkRead(notifications: NotificationLike[]) {
      if (notifications.length > 0) {
        await NotifierApi.markRead({
          notificationIds: notifications.map((el) => el._id),
        });
      }
    },
  },
  watch: {
    tab(v: 'open' | 'dismissed') {
      this.$nextTick(() => {
        if (v === 'dismissed') {
          const unreadNotifications = this.notifications.filter((el) => !el.readAt && !el.dismissedAt);
          this.bulkRead(unreadNotifications);
        } else if (v === 'open') {
          const unreadNotifications = this.notifications.filter((el) => !el.readAt && !!el.dismissedAt);
          this.bulkRead(unreadNotifications);
        } else {
          throw new Error('Unknown tab');
        }
      });
    },
    showMenu(v: boolean) {
      if (v) {
        if (this.notifications.length === 0) {
          this.loadNotifications();
        }
        this.$store.state.rtcNotifications.forEach((el: NotificationLike) => {
          el.showInApp = false;
        });
      } else if (!v) {
        this.$nextTick(async () => {
          const unreadNotifications = this.notifications.filter((el) => !el.readAt);
          await this.bulkRead(unreadNotifications);
          this.tab = 'open';
        });
      }
    },
  },
  mounted() {
    this.initialize();
    this.muted = SoundManager.isMuted;
    NotificationBus.$on('pushRtc', (notification) => {
      if (!notification.readAt) {
        this.unreadCount++;
      }
      this.notifications.unshift(notification);
    });
    NotificationBus.$on('update', () => {
      this.loadNotifications();
    });
    NotificationBus.$on(NotifierEvents.MARK_READ, (ids: mongoose.Types.ObjectId[]) => {
      this.$nextTick(() => {
        ids.forEach((id) => {
          const index = this.notifications.findIndex((el) => el._id === id && !el.readAt);
          if (index >= 0) {
            this.$set(this.notifications[index], 'readAt', new Date());
            this.unreadCount--;
            this.$store.commit('hideNotification', id);
          }
        });
      })
    });
    NotificationBus.$on(NotifierEvents.DISMISS, (ids: mongoose.Types.ObjectId[]) => {
      ids.forEach((id) => {
        const index = this.notifications.findIndex((el) => el._id === id);
        if (index >= 0) {
          this.$set(this.notifications[index], 'dismissedAt', new Date());
          this.$store.commit('hideNotification', id);
        }
      });
    });
    NotificationBus.$on(NotifierEvents.REVOKE_DISMISSION, (ids: mongoose.Types.ObjectId[]) => {
      ids.forEach((id) => {
        const index = this.notifications.findIndex((el) => el._id === id);
        if (index >= 0) {
          this.$delete(this.notifications[index], 'dismissedAt');
        }
      });
    });
  },
});
</script>

import {LoginUser} from '@/interfaces/loginUser.interface';
import Axios from 'axios';
import {Types} from 'mongoose';
import {API} from './api.class';
import {FindLoginUserRequestDto} from '@/classes/dto/user-login-handler/request/FindLoginUser.request.dto';
import {ActivateLoginUserRequestDto} from '@/classes/dto/user-login-handler/request/ActivateLoginUser.request.dto';
import {FindByIdOptions} from '@/interfaces/findByIdOptions';
import {CreateLoginUserRequestDto} from '@/classes/dto/user-login-handler/request/CreateLoginUser.request.dto';
import {UpdateLoginUserRequestDto} from '@/classes/dto/user-login-handler/request/UpdateLoginUser.request.dto';

/**
 * This class is used for communicating with user-login-handler
 * @class UserLoginHandlerApi
 * @author mklaczinski
 */
export class UserLoginHandlerApi extends API {
    static service = 'user-login-handler';

    static async activate(obj: ActivateLoginUserRequestDto): Promise<boolean | void> {
        return Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/activate`, obj);
    }

    static create(data: CreateLoginUserRequestDto): Promise<void> {
        return this.post(``, data);
    }

    static async findByMeta(id: Types.ObjectId): Promise<LoginUser> {
        return this.get(`meta/${id}`, '');
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptions): Promise<LoginUser> {
        return this.get(`${id}`, options);
    }

    static async find(data: FindLoginUserRequestDto): Promise<{ loginUsers: LoginUser[]; maxPage: number }> {
        return this.get(``, data);
    }

    static async update(id: Types.ObjectId, update: UpdateLoginUserRequestDto): Promise<LoginUser> {
        return this.put(`${id}`, update);
    }

    static async deactivate(id: Types.ObjectId): Promise<LoginUser> {
        return this.put(`deactivate/${id}`, {});
    }

    static async reactivate(id: Types.ObjectId): Promise<LoginUser> {
        return this.put(`reactivate/${id}`, {});
    }

    static async getWidgets(): Promise<string[]> {
        return this.get('widgets');
    }

    static async setWidgets(widgets: string[]): Promise<void> {
        await this.post('widgets', widgets);
    }
}

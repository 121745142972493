<template>
    <v-container class="fill-height">
        <v-row align="center" class="fill-height" justify="center">
            <v-col cols="12" lg="5" md="6" sm="9" xl="3">
                <v-card>
                    <v-card-title>
                        Anmeldung via Anmeldecode
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Bitte geben Sie Ihren Anmeldecode ein.
                            Trennzeichen wie Bindestriche o.ä. müssen nicht berücksichtigt werden.
                        </p>
                      <v-alert type="info">
                        Sie finden den Anmeldecode rechts unten auf der Anmeldung.
                      </v-alert>
                        <v-otp-input v-model="modelCode" color="info" length="6"/>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn :disabled="code.length < 6" :loading="loading" block color="info" @click="submitCode">
                            Anmelden
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script lang="ts">
import Vue from 'vue';
import Axios from 'axios';

export default Vue.extend({
    data: () => ({
        code: '',
        loading: false,
    }),
    computed: {
        modelCode: {
            get() {
                return this.code.toUpperCase();
            },
            set(v: string) {
                this.code = v.toUpperCase();
            },
        },
    },
    methods: {
        async submitCode() {
            this.$$showSnackbar('Buchungscode wird überprüft...', 'info');
            this.loading = true;
            try {
                const resp = await Axios.get(`${process.env.VUE_APP_API_PATH}/booking-manager/form-builder/form-server/validate/${this.code}`);
                if (resp.data) {
                    await this.$router.push('/form/' + this.code);
                } else {
                    this.$$showSnackbar('Der Buchungscode scheint ungültig. Bitte prüfen Sie Ihre Eingabe', 'error');
                }
            } catch (e) {
                this.$$showSnackbar('Fehler beim Überprüfen des Buchungscodes', 'error', e);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        code(v: string) {
            if (v.length === 6) {
                this.submitCode();
            }
        },
    },
});
</script>

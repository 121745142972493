<template>
  <v-slide-x-reverse-transition class="message-container" group
                                style="overflow-y: scroll; max-height: calc(100vh - 40px);" tag="div">
    <div v-if="notifications.length > 1" key="dismiss_all" class="text-right mb-1 mt-3">
      <v-btn rounded small @click="readAll">
        Alle als gelesen markieren
      </v-btn>
    </div>
    <template v-for="notification in notifications">
      <component :is="getComponent(notification)" :key="notification._id" :component="getComponent(notification)"
                 :notification="notification"/>
    </template>
  </v-slide-x-reverse-transition>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import MentionNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/mentionNotificationAlert.vue';
import {NotificationTypes} from '@/enums/notifier/NotificationTypes';
import OverdueToDoNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/overdueToDoNotificationAlert.vue';
import {NotifierApi} from '@/classes/api/notifier.api';
import ToDoCompletedNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/toDoCompletedNotificationAlert.vue';
import ToDoAssignedNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/toDoAssignedNotificationAlert.vue';
import CallNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/callNotificationAlert.vue';
import SupervisedEntityDeactivatedNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/supervisedEntityDeactivatedNotificationAlert.vue';
import EntityAssignedToSupervisorNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/entityAssignedToSupervisorNotificationAlert.vue';
import WhatsAppMessageTemplateStatusUpdateNotificationAlert
  from "@/components/app/appBar/notifications/appNotifications/components/whatsAppMessageTemplateStatusUpdateNotificationAlert.vue";
import CourseCancellationFailedNotificationAlert
  from '@/components/app/appBar/notifications/appNotifications/components/courseCancellationFailedNotificationAlert.vue';

export default Vue.extend({
  computed: {
    notifications(): NotificationLike[] {
      return this.$store.state.rtcNotifications.filter((notification: NotificationLike) => notification.showInApp);
    },
  },
  methods: {
    getComponent(notification: NotificationLike) {
      switch (notification.type) {
        case NotificationTypes.MENTION:
          return MentionNotificationAlert;
        case NotificationTypes.OVERDUE_TO_DO:
          return OverdueToDoNotificationAlert;
        case NotificationTypes.TO_DO_ASSIGNED:
          return ToDoAssignedNotificationAlert;
        case NotificationTypes.TO_DO_COMPLETED:
          return ToDoCompletedNotificationAlert;
        case NotificationTypes.CALL:
          return CallNotificationAlert;
        case NotificationTypes.SUPERVISED_ENTITY_DEACTIVATED:
          return SupervisedEntityDeactivatedNotificationAlert;
        case NotificationTypes.ENTITY_ASSIGNED_TO_SUPERVISOR:
          return EntityAssignedToSupervisorNotificationAlert;
        case NotificationTypes.WHATSAPP_MESSAGE_TEMPLATE_STATUS_UPDATE:
          return WhatsAppMessageTemplateStatusUpdateNotificationAlert;
        case NotificationTypes.COURSE_CANCELLATION_FAILED:
          return CourseCancellationFailedNotificationAlert;
        default:
          return MentionNotificationAlert;
      }
    },
    async readAll() {
      await NotifierApi.markRead({
        notificationIds: this.notifications.map((notification: NotificationLike) => notification._id),
      });
      this.notifications.forEach((notification: NotificationLike) => {
        this.$store.commit('hideNotification', notification._id);
      });
    },
  },
});
</script>

<style scoped>
.message-container {
  max-width: 600px;
  width: 25vw;
  min-width: 400px;
}
</style>
